.loom-companion-mv3 {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
p {
  margin-bottom: 0.2em;
  padding: 0;
  /* background-color: aqua; */
}

.quill.text-editor {
  border: 1px solid #d8e2ef;
  border-radius: 5px;
  min-height: 300px;
}
.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}
.ql-container.ql-snow {
  border: none;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: 'Paragraph';
}
.bg-white {
  box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.5);
}
