.main {
  background-color: transparent;
  /* height: 88vh; */
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  height: 50px;
  margin-top: 20px;
}
.left input {
  border: 0.1px solid gray;
  background-color: transparent;
  width: 300px;
  padding: 4px;
  border-radius: 6px;
}

.right {
  display: flex;
  gap: 8px;
}
.right button {
  padding: 6px 20px;
  color: white;
  border-radius: 6px;
  font-size: 18px;
  border: none;
  box-shadow: rgb(94, 94, 94) 0px 3px 8px;
}
.card {
  width: 330px;
}
.card h1 {
  font-size: 22px;
}

.card-show {
  padding: 10px 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  justify-content: center;
}

.upload_modal_form .form_group span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 0, 0, 0.692);
}
.upload_modal_form .form_group label {
  font-size: 15px;
  font-weight: 500;
}

@media screen and (min-width: 400px) and (max-width: 991px) {
  .nav-bar {
    width: 100vw;
    padding: 0px 8px;
  }
  .filter-container {
    right: 10%;
  }
  .nav-bar {
    height: 60px;
  }
  .card-show {
    width: 100vw;
    padding: 0px 8px;
  }
  .left input {
    width: 200px;
  }
}

.filter-container {
  position: absolute;
  margin-top: 8px;
  right: 4%;
  padding: 10px 20px;
  box-shadow: rgb(51, 49, 49) 0px 3px 8px;
  border-radius: 6px;
  z-index: 10;
}
.filter-content input {
  width: 300px;
  display: block;
  padding: 6px 4px;
}
.filter-content label {
  font-size: 16px;
}

.filter-content textarea {
  width: 300px;
  height: 100px;
  display: block;
}

.form-btn {
  width: 300px;
  margin-top: 10px;
}

.filter-footer {
  display: flex;
  gap: 3px;
}

#fileNameInput {
  width: 100%;
}

.outlet {
  height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Define CSS classes for placeholder color */
.input-dark::placeholder {
  color: white;
}

.input-light::placeholder {
  color: gray;
}
.dark {
  background-color: #0e142a;
  color: white;
}
.light {
  background-color: white;
  color: black;
}

.truncate-multi-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /* Number of lines to show */
  height: 4.5em; /* height = line-height * number of lines */
  line-height: 1.5; /* Adjust based on your line height */
}